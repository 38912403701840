import React from 'react';
import {withRouter, Link} from 'react-router-dom'
import {appConstants} from '../constants/'
import _ from 'lodash'
import $ from 'jquery'
import '../assets/css/pagination.css'
$.DataTable = require('datatables.net')

class TableData extends React.Component{
  constructor(props){
    super(props);
    this.state={
    	data: []
    };
    this.viewData = this.viewData.bind(this)
  }
  componentDidMount(){
    
    this.$el = $(this.el)
		this.$el.DataTable({language: {
        paginate: {
            next: '<span class="p-next">next</span>',
            previous: '<span class="p-previous">previous</span>'
        },
    },
    aaSorting: []
  });
  }


  


 

  viewData(data){
 
  	let list =  data.map((item,index)=> 
  	<tr key={index}>
				<td>{item.value}</td>
				<td>{item.label}</td>
				<td><a href="javascript:void(0)" onClick={() => this.props.showData(item.id)}><i className="fa fa-search"></i></a></td>
			</tr>)
  	return list
  }

  render(){

  	let data  = this.props.data || []
  	return(<div className="table-scroll"><table id="basic-datatables" class="display table table-striped table-hover" ref={el => this.el = el} >
			<thead>
				<tr>
				 {appConstants.TABLE_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</thead>
			<tfoot>
				<tr>
				 {appConstants.TABLE_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</tfoot>
			<tbody>
				{this.viewData(data)}

			</tbody>
		</table></div>)
  }

}
export default (TableData)


