import React from 'react';
import {withRouter,Link} from 'react-router-dom'
import img from '../../assets/images/logo.svg'
class Header extends React.Component{
  constructor(props){
    super(props);
    this.state={
      open: false,
      searchQuery: ''
    };
    

  }

  componentWillReceiveProps(nextProps){

  }
  

  
  componentDidMount() {
   
  }

  handleSubmit(e){
  	e.preventDefault();
  	this.props.getSearchData(this.state.searchQuery)
  }
  handleChange(e){
  	this.setState({searchQuery: e.target.value})
  }



  render(){
  	const user = this.state.user || {} 
	  return (


	  	<div className="main-header">

			<div className="logo-header" data-background-color="blue2">
				
				<a href="index.html" className="logo">
					<i className="fas fa-feather-alt"></i>
					<span>&nbsp; Ministry of Finance</span>
				</a>
				{/*<button onClick={() => this.props.togglePanel()}  className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon">
						<i className="icon-menu"></i>
					</span>
				</button>*/}
				{/*<button onClick={() => this.props.openTopBar()}  className="topbar-toggler more"><i className="icon-options-vertical"></i></button> */}
				<div className="nav-toggle">
					{/*<button onClick={() => this.props.togglePanel()}  className="btn btn-toggle sidenav-overlay-toggler toggled">
					{this.props.toggled ? <i className="icon-options-vertical"></i> : <i className="icon-menu"></i>
						}
					</button>*/}
				</div>
			</div>

			<nav className="navbar navbar-header navbar-expand-lg" data-background-color="blue2">
				
				<div className="container-fluid">
					<div className="collapse" id="search-nav">
						<form onSubmit = {(event) => this.handleSubmit(event)} className="navbar-left navbar-form nav-search mr-md-3">
							<div className="input-group">
								<input onChange={(event) => this.handleChange(event)} type="text" placeholder="Search ..." className="form-control"/>
							  <div className="input-group-prepend">
									<button type="submit" className="btn btn-search pr-1">
										<i className="fa fa-search search-icon"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
					{/*<ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
						<li className="nav-item toggle-nav-search hidden-caret">
							<a className="nav-link" data-toggle="collapse" href="#search-nav" role="button" aria-expanded="false" aria-controls="search-nav">
								<i className="fa fa-search"></i>
							</a>
						</li>
						<li className="nav-item dropdown hidden-caret">
							<a className="nav-link dropdown-toggle" href="#" id="messageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i className="fa fa-envelope"></i>
							</a>
							<ul className="dropdown-menu messages-notif-box animated fadeIn" aria-labelledby="messageDropdown">
								<li>
									<div className="dropdown-title d-flex justify-content-between align-items-center">
										Messages 									
										<a href="#" className="small">Mark all as read</a>
									</div>
								</li>
								<li>
									<div className="scroll-wrapper message-notif-scroll scrollbar-outer" style={{"position": "relative"}}><div className="message-notif-scroll scrollbar-outer scroll-content" style={{"height": "auto", "marginBottom": "0px", "marginRight": "0px", "maxHeight": "0px"}}>
										<div className="notif-center">
											<a href="#">
												<div className="notif-img"> 
													<img src="../assets/img/jm_denis.jpg" alt="Img Profile"/>
												</div>
												<div className="notif-content">
													<span className="subject">Jimmy Denis</span>
													<span className="block">
														How are you ?
													</span>
													<span className="time">5 minutes ago</span> 
												</div>
											</a>
											<a href="#">
												<div className="notif-img"> 
													<img src="../assets/img/chadengle.jpg" alt="Img Profile"/>
												</div>
												<div className="notif-content">
													<span className="subject">Chad</span>
													<span className="block">
														Ok, Thanks !
													</span>
													<span className="time">12 minutes ago</span> 
												</div>
											</a>
											<a href="#">
												<div className="notif-img"> 
													<img src="../assets/img/mlane.jpg" alt="Img Profile"/>
												</div>
												<div className="notif-content">
													<span className="subject">Jhon Doe</span>
													<span className="block">
														Ready for the meeting today...
													</span>
													<span className="time">12 minutes ago</span> 
												</div>
											</a>
											<a href="#">
												<div className="notif-img"> 
													<img src="../assets/img/talha.jpg" alt="Img Profile"/>
												</div>
												<div className="notif-content">
													<span className="subject">Talha</span>
													<span className="block">
														Hi, Apa Kabar ?
													</span>
													<span className="time">17 minutes ago</span> 
												</div>
											</a>
										</div>
									</div><div className="scroll-element scroll-x" ><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar ui-draggable ui-draggable-handle"></div></div></div><div className="scroll-element scroll-y" ><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar ui-draggable ui-draggable-handle"></div></div></div></div>
								</li>
								<li>
									<a className="see-all" href="javascript:void(0);">See all messages<i className="fa fa-angle-right"></i> </a>
								</li>
							</ul>
						</li>
						<li className="nav-item dropdown hidden-caret submenu">
							<a className="nav-link dropdown-toggle" href="#" id="notifDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i className="fa fa-bell"></i>
								<span className="notification">4</span>
							</a>
							<ul className="dropdown-menu notif-box animated fadeIn" aria-labelledby="notifDropdown">
								<li>
									<div className="dropdown-title">You have 4 new notification</div>
								</li>
								<li>
									<div className="scroll-wrapper notif-scroll scrollbar-outer" style={{"position": "relative"}}><div className="notif-scroll scrollbar-outer scroll-content" style={{"height": "auto", "marginBottom": "0px", "marginRight": "0px","maxHeight": "256px"}}>
										<div className="notif-center">
											<a href="#">
												<div className="notif-icon notif-primary"> <i className="fa fa-user-plus"></i> </div>
												<div className="notif-content">
													<span className="block">
														New user registered
													</span>
													<span className="time">5 minutes ago</span> 
												</div>
											</a>
											<a href="#">
												<div className="notif-icon notif-success"> <i className="fa fa-comment"></i> </div>
												<div className="notif-content">
													<span className="block">
														Rahmad commented on Admin
													</span>
													<span className="time">12 minutes ago</span> 
												</div>
											</a>
											<a href="#">
												<div className="notif-img"> 
													<img src="../assets/img/profile2.jpg" alt="Img Profile"/>
												</div>
												<div className="notif-content">
													<span className="block">
														Reza send messages to you
													</span>
													<span className="time">12 minutes ago</span> 
												</div>
											</a>
											<a href="#">
												<div className="notif-icon notif-danger"> <i className="fa fa-heart"></i> </div>
												<div className="notif-content">
													<span className="block">
														Farrah liked Admin
													</span>
													<span className="time">17 minutes ago</span> 
												</div>
											</a>
										</div>
									</div><div className="scroll-element scroll-x" ><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar ui-draggable ui-draggable-handle" style={{"width": "100px"}}></div></div></div><div className="scroll-element scroll-y"><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar ui-draggable ui-draggable-handle" style={{"height": "100px"}}></div></div></div></div>
								</li>
								<li>
									<a className="see-all" href="javascript:void(0);">See all notifications<i className="fa fa-angle-right"></i> </a>
								</li>
							</ul>
						</li>
						<li className="nav-item dropdown hidden-caret">
							<a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
								<i className="fas fa-layer-group"></i>
							</a>
							<div className="dropdown-menu quick-actions quick-actions-info animated fadeIn">
								<div className="quick-actions-header">
									<span className="title mb-1">Quick Actions</span>
									<span className="subtitle op-8">Shortcuts</span>
								</div>
								<div className="scroll-wrapper quick-actions-scroll scrollbar-outer" style={{"position": "relative"}}><div className="quick-actions-scroll scrollbar-outer scroll-content" style={{"height": "auto","marginBottom": "0px", "marginRight": "0px", "maxHeight": "0px"}}>
									<div className="quick-actions-items">
										<div className="row m-0">
											<a className="col-6 col-md-4 p-0" href="#">
												<div className="quick-actions-item">
													<div className="avatar-item bg-danger rounded-circle">
														<i className="far fa-calendar-alt"></i>
													</div>
													<span className="text">Calendar</span>
												</div>
											</a>
											<a className="col-6 col-md-4 p-0" href="#">
												<div className="quick-actions-item">
													<div className="avatar-item bg-warning rounded-circle">
														<i className="fas fa-map"></i>
													</div>
													<span className="text">Maps</span>
												</div>
											</a>
											<a className="col-6 col-md-4 p-0" href="#">
												<div className="quick-actions-item">
													<div className="avatar-item bg-info rounded-circle">
														<i className="fas fa-file-excel"></i>
													</div>
													<span className="text">Reports</span>
												</div>
											</a>
											<a className="col-6 col-md-4 p-0" href="#">
												<div className="quick-actions-item">
													<div className="avatar-item bg-success rounded-circle">
														<i className="fas fa-envelope"></i>
													</div>
													<span className="text">Emails</span>
												</div>
											</a>
											<a className="col-6 col-md-4 p-0" href="#">
												<div className="quick-actions-item">
													<div className="avatar-item bg-primary rounded-circle">
														<i className="fas fa-file-invoice-dollar"></i>
													</div>
													<span className="text">Invoice</span>
												</div>
											</a>
											<a className="col-6 col-md-4 p-0" href="#">
												<div className="quick-actions-item">
													<div className="avatar-item bg-secondary rounded-circle">
														<i className="fas fa-credit-card"></i>
													</div>
													<span className="text">Payments</span>
												</div>
											</a>
										</div>
									</div>
								</div><div className="scroll-element scroll-x" ><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar ui-draggable ui-draggable-handle"></div></div></div><div className="scroll-element scroll-y" ><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar ui-draggable ui-draggable-handle"></div></div></div></div>
							</div>
						</li>
						<li className="nav-item submenu">
							<a href="#" className="nav-link quick-sidebar-toggler">
								<i className="fa fa-th"></i>
							</a>
						</li>
						<li className="nav-item dropdown hidden-caret submenu">
							<a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
								<div className="avatar-sm">
								
								</div>
							</a>
							<ul className="dropdown-menu dropdown-user animated fadeIn">
								<div className="scroll-wrapper dropdown-user-scroll scrollbar-outer" style={{"position": "relative"}}><div className="dropdown-user-scroll scrollbar-outer scroll-content" style={{"height": "auto", "marginBottom": "0px", "marginRight": "0px","maxHeight": "271px"}}>
									<li>
										<div className="user-box">
											<div className="avatar-lg"><img src="../assets/img/profile.jpg" alt="image profile" className="avatar-img rounded"/></div>
											<div className="u-text">
												<h4>Hizrian</h4>
												<p className="text-muted">hello@example.com</p><a href="profile.html" className="btn btn-xs btn-secondary btn-sm">View Profile</a>
											</div>
										</div>
									</li>
									<li>
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" href="#">My Profile</a>
										<a className="dropdown-item" href="#">My Balance</a>
										<a className="dropdown-item" href="#">Inbox</a>
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" href="#">Account Setting</a>
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" href="#">Logout</a>
									</li>
								</div><div className="scroll-element scroll-x" ><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar ui-draggable ui-draggable-handle" style={{"width": "100px"}}></div></div></div><div className="scroll-element scroll-y" ><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar ui-draggable ui-draggable-handle" style={{"height": "100px"}}></div></div></div></div>
							</ul>
						</li>
					</ul>*/}
				</div>
			</nav>
		</div>
	  );
	};
}


export default withRouter(Header);


