export const appConstants = {
 //WEB_SERVICE_URL: ('http://162.243.172.109:3100/api/v1'),
 WEB_SERVICE_URL: (process.env && process.env.REACT_APP_API_HOST),
  //SUB_DOMAIN: 'cash',
  API_V: 'api/v1',

  TABLE_COLUMNS: [
  {title:"Tariff Code"},
  {title:"Description"},
  {title: "Actions"}]
}
