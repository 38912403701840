import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/atlantis.min.css';
import axios from 'axios';
import Header from  './components/shared/Header'
import Footer from  './components/shared/Footer'
import SidePane from  './components/shared/SidePane'
import Dashboard from  './components/Dashboard'
import {appConstants} from './constants/'
import Loader from './components/helpers/Loader'
class App extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      results: [],
      loading: false,
      currentData: {}

    }
    this.openTopBar = this.openTopBar.bind(this);
    this.togglePanel = this.togglePanel.bind(this);
    this.getSearchData = this.getSearchData.bind(this)
  }
  

  togglePanel(){

    this.setState({toggled: !this.state.toggled})
    if(!this.state.toggled){
     document.getElementsByTagName('html')[0].classList.add('nav_open')
    }
    else{
      document.getElementsByTagName('html')[0].classList.remove('nav_open')
    }


  }

  openTopBar(){
     this.setState({topToggle: !this.state.topToggle})
     if(!this.state.topToggle){
     document.getElementsByTagName('html')[0].classList.add('topbar_open')
    }
    else{
      document.getElementsByTagName('html')[0].classList.remove('topbar_open')
    }
  }



  getSearchData(query){
    this.setState({loading: true, results: [],currentData: {}})
    axios.get(`${appConstants.WEB_SERVICE_URL}/hs_codes?query=${query}`)
    .then((response) => {
      this.setState({results: response.data.hscodes, loading: false})
    })
    .catch((err) => {
      
    })

  }

  showTableData=(id) => {
    this.setState({loading: true})
    axios.get(`${appConstants.WEB_SERVICE_URL}/hs_codes/${id}`)
    .then((response) => {
      this.setState({currentData: response.data.hscode, loading: false})
    })
    .catch((err) => {
    })
  }

  render(){
    return(
      <Router>
      <Loader {...this.state} />
       <div  className={`wrapper overlay-sidebar ${this.state.toggled ? 'is-show' : ''}`}>
        <Header getSearchData={this.getSearchData} togglePanel={this.togglePanel} {...this.state} openTopBar={this.openTopBar} />
        <SidePane {...this.state}/>
        <div className="main-panel">
          <Route path='/' exact render={routeProps => <Dashboard {...routeProps} showTableData={this.showTableData} currentData={this.state.currentData} loading={this.state.loading} data={this.state.results}/>} />
          <Footer/>
        </div>
      </div>
    </Router>
    )
  }
}



export default App;
