import React from 'react';
import $ from 'jquery'
import {withRouter, Link} from 'react-router-dom'
import '../../assets/css/pagination.css'
$.DataTable = require('datatables.net')


class EmptyTable extends React.Component{
 constructor(props){
 	super(props)
 	this.state= {
 		
 	}
 }

 componentDidMount(){
 	//  this.$el = $(this.el)
	 // this.$el.DataTable()
 }
 
 render(){
 	 let {columns} = this.props
 	 return(<table id="empty-basic-datatables" className="display table table-striped table-hover" ref={el => this.el = el} >
			<thead>
				<tr>
				 {columns.map((item) => <th>{item.title}</th>)}
				</tr>
			</thead>
			<tfoot>
				<tr>
				 {columns.map((item) => <th>{item.title}</th>)}
				</tr>
			</tfoot>
			<tbody>
				<tr class="odd"><td valign="top" colspan={columns.length} style={{textAlign: 'center'}} class="dataTables_empty">No data available in table</td></tr>
			</tbody>
		</table>)
 }
}

/** 
 * This is a wrapper method that connects your alert to the store based on a *alertName key. This is the unique identifier that will allow you to both show the alert and dismiss the alert. 
 */
export default EmptyTable;