import React from 'react';
import {withRouter} from 'react-router-dom'
import TableData from './TableData'
import _ from 'lodash'
import EmptyTable from './helpers/EmptyTable'
import {appConstants} from '../constants/'
import axios from 'axios'
//import MyAlert from './helpers/MyAlert';
//import "../assets/js/core/jquery.3.2.1.min.js"

// import { Sparklines, SparklinesLine,SparklinesBars } from 'react-sparklines';
// import { VectorMap } from "react-jvectormap"


// import "../assets/js/plugin/jquery.sparkline/jquery.sparkline.min.js"
// import $ from 'jquery'

// import {staticalCircle,incomeChart,userStaticChart,providerChart} from "../assets/js/dashboard.js"
class Dashboard extends React.Component{
  constructor(props){
    super(props);
    this.state={
      open: false,
    };
    

  }

  componentWillReceiveProps(nextProps){
  	// if(nextProps.data){
  	// 	this.setState({data: nextProps.data})
  	// 	staticalCircle(nextProps.data)
  	// 	incomeChart(nextProps.data)
  	// 	userStaticChart(nextProps.data)
  	// }
  }
  

  
  componentDidMount() {
  	// this.props.getDashboard()
  	// providerChart()
  }


  showData = (id) => {
  	this.props.showTableData(id)
  }



  // renderMap(){
  // 	return( 

  // 		<VectorMap
  //     map={"world_mill"}
  //     backgroundColor="transparent" //change it to blue !!!
  //     zoomOnScroll={false}
  //     containerStyle={{
  //       width: "100%",
  //       height: "520px"
  //     }}
  //     onRegionClick={this.handleClick}  //gets the country code
  //     containerClassName="map"
  //     regionStyle={{
  //       initial: {
  //         fill: "#e4e4e4",
  //         "fill-opacity": 0.9,
  //         stroke: "none",
  //         "stroke-width": 0,
  //         "stroke-opacity": 0
  //       },
  //       hover: {
  //         "fill-opacity": 0.8,
  //         cursor: 'pointer'
  //       },
  //       selected: {
  //         fill: '#2938bc'  //what colour clicked country will be
  //       },
  //       selectedHover: {
  //       }      
  //     }}
  //     regionsSelectable={true}
  //     series={{
  //       regions: [
  //         {
  //           values: [],  //this is your data
  //           scale: ["#146804", "#ff0000"],  //your color game's here
  //           normalizeFunction: "polynomial"
  //         }
  //       ]
  //     }}
  //   />)
  // }

  render(){
  	let {data,loading,currentData} = this.props

  	// let countIncome = 0
  	// this.state.data.total_income && this.state.data.total_income.map((t)=> {countIncome = countIncome + t.total_price })
  	// let {error} = this.props;
	  return (

	  	  <React.Fragment>
	  	  	
					<div className="content dashboard-section">
						<div className="panel-header bg-primary-gradient">
							<div className="page-inner py-5">
								<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
									<div>
										<h2 className="text-white pb-2 fw-bold">Import Taxes Search</h2>
										<h5 className="text-white op-7 mb-2">Customs and Excise</h5>
									</div>
									<div className="ml-md-auto py-2 py-md-0">
										{/*<a href="#" className="btn btn-white btn-border btn-round mr-2">Manage</a>
										<a href="#" className="btn btn-secondary custom-btn btn-round">Add Customer</a>*/}
									</div>
								</div>
							</div>
						</div>
						<div className="page-inner mt--5">
				
							<div className="row">
								<div className="col-md-12">
										<div className="card-body">
											<div className="row">
												<div className="col-md-8">
													<div className="card card-shadow">
														<div class="card-header">Results</div>
														<div className="card-body">
															{
																_.isEmpty(data) ?
																<EmptyTable columns={appConstants.TABLE_COLUMNS} />  : 
																<div><div><TableData {...this.props} showData={this.showData} /></div></div>

															}			
														</div>		
													</div>
												</div>
												<div className="col-md-4">
													<div className="card card-shadow">
														<div class="card-header">View Data</div>
														<div className="card-body">
													
													{

														_.isEmpty(currentData) ? null : 
														<div>
														  {Object.keys(currentData).map((item)=> 
															<div className="row">
																<div className="col-md-4 bt-700 pb-10">
																 {item}
																</div>
																<div className="col-md-8">
																{currentData[item]}
																</div>
															</div>)}
														</div>
													}		
												</div>
												</div>
												</div>
											</div>
										</div>
							
								</div>
								{/*<div className="col-md-4">
									<div className="card card-primary">
										<div className="card-header">
											<div className="card-title">Daily Sales</div>
											<div className="card-category">March 25 - April 02</div>
										</div>
										<div className="card-body pb-0">
											<div className="mb-4 mt-2">
												<h1>$4,578.58</h1>
											</div>
											<div className="pull-in">
												<canvas id="dailySalesChart"></canvas>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-body pb-0">
											<div className="h1 fw-bold float-right text-warning">+7%</div>
											<h2 className="mb-2">213</h2>
											<p className="text-muted">Transactions</p>
											<div className="pull-in sparkline-fix">
												<Sparklines data={[105,103,123,100,95,105,115]} width={100} height={25}  >
													<SparklinesLine style={{stroke: "#FFAA53" ,fill: "#09f7f7", fillOpacity: "1" }} />
												</Sparklines>
											</div>
										</div>
									</div>
								</div>*/}
							</div>
						
						
						</div>
					</div>
				  </React.Fragment>
	 			
	  );
	};
}




export default withRouter(Dashboard);
