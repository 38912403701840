import React from 'react';
import {withRouter,Link} from 'react-router-dom'

class SidePane extends React.Component{
  constructor(props){
    super(props);
    this.state={
      open: false,
    };
    

  }

  componentWillReceiveProps(nextProps){
  
  }
  

  
  componentDidMount() {
  	
  }


  render(){
  	let pathname = this.props && this.props.history.location.pathname
  	const user = this.state.user || {} 
	  return (


	  			<div className="sidebar sidebar-style-2">			
			<div className="sidebar-wrapper scrollbar scrollbar-inner">
				<div className="sidebar-content">
					<div className="user">
						<div className="avatar-sm float-left mr-2">
							<img src="https://via.placeholder.com/60x60" alt="..." className="avatar-img rounded-circle"/>
						</div>
						<div className="info">
							<a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
								<span>
									Hizrian
									<span className="user-level">Administrator</span>
									<span className="caret"></span>
								</span>
							</a>
							<div className="clearfix"></div>

							<div className="collapse in" id="collapseExample">
								<ul className="nav">
									<li>
										<a href="#profile">
											<span className="link-collapse">My Profile</span>
										</a>
									</li>
									<li>
										<a href="#edit">
											<span className="link-collapse">Edit Profile</span>
										</a>
									</li>
									<li>
										<a href="#settings">
											<span className="link-collapse">Settings</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<ul className="nav nav-primary">
						<li class={`nav-item  ${pathname == '/' ? 'active' : ''}`}>
							<Link to={"/"}>
									<i class="far fa-calendar-alt"></i>
									<p>Dashboard</p>
									<span class="badge badge-info"></span>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>

			  );
	};
}





export default withRouter(SidePane);
