import React from 'react';
import {Link} from 'react-router-dom'
const Footer = () => {
	return(
			<footer className="footer">
				<div className="container-fluid">
					<nav className="pull-left">
						<ul className="nav">
							<li className="nav-item">
							
							</li>
						
						</ul>
					</nav>
					<div className="copyright ml-auto">
						  <Link to={"/"}>  Ministry of Finance</Link>
					</div>				
				</div>
			</footer>
	)};

export default Footer;